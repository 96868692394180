import React from 'react'
import Navbar from '../components/Navbar'
import UserHeader from '../components/UserHeader'
import { useNavigate } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../utils/AppFunctions'
import config from '../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export default function MainLayout({ children }) {
    const navigate = useNavigate()

    return (
        <div style={{ width: "100vw", }}>
            <ToastContainer />
            <div className='row' style={{ width: "100%" }}>
                <div style={{ width: "100%", maxHeight: "100vh", overflowY: "scroll" }}>
                    <UserHeader />
                    <div className="container">
                        <div className="inner-container" id='container'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
