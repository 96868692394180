import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import notify from '../../utils/IANotification'
import { MdEdit, MdPhotoAlbum } from 'react-icons/md'
import MainLayout from '../../layouts/MainLayout'

export default function EventsPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const [search, setSearch] = useState("")

  const fetchData = async () => {
    let endpoint = config.endpoint + "/events/"
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let response = await fetchResponse.json()
    if (response.status === 200) {
      setData(response.payload)
      setLoading(false)
    } else {
      notify({ message: response.message, status: response.status })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <MainLayout>
      <div className="row justifySplit alignCenter">
        <p style={{ fontWeight: "600", fontSize: 20 }}>Events</p>
        <button className="button"
          onClick={() => navigate("/events/create")}
        >+ Event</button>
      </div>

      {loading ? <p>Loading...</p> :
        <div className="justifyCenter mt-20" style={{ width: "100%" }}>
          {data.map((event, index) => {
            if (JSON.stringify(event).toLowerCase().includes(search.toLowerCase())) {
              return (
                <div className='mt-10 row gap-10 alignCenter card justifySplit' style={{ width: "100%", padding: 0 }}>
                  <div className="row gap-10">
                    <img src={`${config.endpoint}${event?.header_image?.file_path}`} alt="" style={{ width: 100, height: 150, objectFit: "cover", }} />
                    <div className='pt-20'>
                      <p>{event.name}</p>
                      <p style={{ color: "gray", fontSize: 12 }}>{event.description}</p>
                    </div>
                  </div>
                  <div style={{ gap: 10, display: "flex" }}>
                    <button className="backgroundLess gap-10 row pr-20"
                      onClick={() => {
                        navigate(`/events/${event.id}`)
                      }}
                    >
                      <MdEdit size={20} color='gray' />
                      <p style={{ color: "gray" }}>View Event</p>
                    </button>
                    <button className="backgroundLess gap-10 row pr-20"
                      onClick={() => {
                        navigate(`/events/gallery/${event.url}`)
                      }}
                    >
                      <MdPhotoAlbum size={20} color='gray' />
                      <p style={{ color: "gray" }}>View Photos</p>
                    </button>
                  </div>
                </div>
              )
            }
          })}
        </div>
      }
    </MainLayout>
  )
}
