import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import config from '../../app-config'
import Header from '../../components/Header'

export default function ErrorPage() {

    useEffect(() => {
    }, [])

    return (
        <div>
            <Header />
            <div className="column alignCenter justifyCenter" style={{height:`calc(100vh - 200px)`, width:"100%", }}>
                <h1 style={{ fontSize: 50, fontWeight: "normal" }}>Looks like you're lost</h1>
                <h2 style={{ fontSize: 20, fontWeight: "normal" }}>The page you are looking for is not available, click <a href="/">here</a> to go back to the home page</h2>
                <p style={{ fontSize: 12, }}>Error 404</p>
            </div>
        </div>
    )
}
