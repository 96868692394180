import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Switch from "react-switch"
import notify from '../../utils/IANotification'
import QRCode from "qrcode.react";
import * as copy from 'copy-to-clipboard';
import LandingPage from '../../pages/guest/LandingPage'
import { HexColorPicker } from "react-colorful";
import { isMobile } from '../../utils/helper'
import MainLayout from '../../layouts/MainLayout'


export default function EventsFormPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))


  const id = useParams().event_id

  const [form, setForm] = useState({
    name: "",
    description: "",
    start_date: "",
    end_date: "",
    header_image_id: "",

    users: [], // {username: "", email:""}
    visibility: "private", // private, public

    // permissions
    can_view_all: false,
    can_download_all: false,
    can_view_users: false,
    guest_mode: false,
    is_unlimited: false,
    limit: 0,
  })

  const [headerImage, setHeaderImage] = useState()
  const [temp, setTemp] = useState("")


  const createEvent = async () => {
    let newForm = new FormData()
    newForm.append("data", JSON.stringify(form))
    newForm.append("header_image", headerImage)

    let endpoint = config.endpoint + "/events/create"
    let fetchResponse = await fetch(endpoint, {
      method: "POST",
      credentials: "include",
      body: newForm
    })
    let data = await fetchResponse.json()
    if (data.status == 200) {
      window.location.href = "/events"
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const editEvent = async () => {
    let newForm = new FormData()
    newForm.append("data", JSON.stringify(form))
    if (headerImage) {
      headerImage?.forEach((image) => {
        newForm.append("header_image", image)
      })
    }

    let endpoint = config.endpoint + "/events/id/" + id
    let fetchResponse = await fetch(endpoint, {
      method: "PUT",
      credentials: "include",
      body: newForm
    })
    let data = await fetchResponse.json()
    if (data.status == 200) {
      notify({ message: data.message, type: "success" })
    } else {
      notify({ message: data.message, type: "error" })
    }
  }

  const fetchData = async () => {
    if (id == "create") return
    let endpoint = config.endpoint + "/events/id/" + id
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    let response = await fetchResponse.json()
    if (response.status == 200) {
      setForm({
        ...response.payload,
        users: response.payload?.users?.map((user) => {
          return {
            username: user.username,
            email: user.email
          }
        }) || []
      })
    } else {
      notify({ message: response.message, type: "error" })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const downloadQr = () => {
    const canvas = document?.getElementById("qr-code");
    if (!canvas?.toDataURL()) return
    const pngUrl = canvas?.toDataURL("image/png")?.replace("image/png", "image/octet-stream");
    let downloadLink = document?.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${form.name}.png`
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <MainLayout>
      <div className="">
        <p style={{ fontWeight: "600", fontSize: 20 }}>{id == "create" ? "Create" : "Edit"} Event</p>
        <p className="subtitle">{form.name || "Start sharing your pics with WePics"}</p>
      </div>

      <div className="card mt-10">
        <p className="card-title">Event Details</p>
        <div className="row gap-10 mt-10" style={{ width: "100%" }}>
          <div className='column' style={{ width: "100%" }}>
            <div className="input-container">
              <label className='input-label' htmlFor="name">Event Name</label>
              <input type="text" name="name" id="name" className='text-input' placeholder='E.g. Seekers Outing' value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} />
            </div>
            <div className="input-container">
              <label className='input-label' htmlFor="description">Description</label>
              <textarea name="description" id="description" className='textarea-input' placeholder='E.g. Nights out at Universal Studio Singapore!' value={form.description} onChange={(e) => setForm({ ...form, description: e.target.value })} />
            </div>
          </div>

          {/* Header Image Upload */}
          <div className='column' style={{ width: "30%", height: "100%" }}>
            <div className="input-container" style={{ height: "100%" }}>
              <label className='input-label' htmlFor="header_image">Header Image</label>
              <input type="file" name="header_image" id="header_image" className='text-input' style={{ height: "100%" }} placeholder='E.g. Seekers Outing'
                onChange={(e) => {
                  let files = e.target.files
                  let newFiles = []
                  for (let i = 0; i < files.length; i++) {
                    newFiles.push(files[i])
                  }
                  console.log(newFiles)
                  setHeaderImage(newFiles);
                }} />
            </div>
          </div>
        </div>
        <div className='input-container'>
          <label htmlFor="" className="input-label">Theme</label>
          <div className={`${isMobile(window.innerWidth) ? "column" : "row"} gap-10 alignStart`}>
            <div style={{ width: !isMobile(window.innerWidth) ? 300 : "100%", height: 50, borderRadius: 10, border: "2px solid black", backgroundColor: form?.colours?.toLowerCase() }}></div>
            <HexColorPicker color={form?.colours?.toLowerCase()}
              style={{ width: "100%", height: 100, borderRadius: 10 }}
              onChange={
                (color) => setForm({ ...form, colours: color })
              } />
          </div>
        </div>
        <div className="column" style={{ width: "100%" }}>
          <div className="input-container">
            <label className='input-label' htmlFor="start_date">Start Date</label>
            <input type="date" name="start_date" id="start_date" className='text-input' placeholder='E.g. Seekers Outing' value={form.start_date} onChange={(e) => setForm({ ...form, start_date: e.target.value })} />
          </div>
          <div className="input-container">
            <label className='input-label' htmlFor="end_date">End Date</label>
            <input type="date" name="end_date" id="end_date" className='text-input' placeholder='E.g. Seekers Outing' value={form.end_date} onChange={(e) => setForm({ ...form, end_date: e.target.value })} />
          </div>
        </div>
      </div>

      <div className="card mt-10">
        <p className="card-title">Guests</p>
        <p className="subtitle" style={{ fontSize: 13 }}>Invite your friends to join your event</p>

        <div className="row justifySplit alignCenter mt-10" style={{ width: "100%" }}>
          <label className='input-label' htmlFor="can_view_all" style={{ fontSize: 16 }}>Visibility</label>
          <div className="gap-10 row alignCenter">
            <p>Private</p>
            <Switch
              checked={form.visibility == "public"}
              onChange={(e) => setForm({ ...form, visibility: e == 1 ? "public" : "private" })}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            />
            <p>Public</p>
          </div>
        </div>


        {form.visibility == "private" &&
          <>
            <div className="row gap-10 justifySplit mt-10" style={{ display: "flex", alignItems: "flex-end" }}>
              <div className="input-container" style={{ margin: 0 }}>
                <label className='input-label' htmlFor="guests" style={{ fontSize: 16 }}>Guests</label>
                <input type="text" name="guests" id="guests" className='text-input' placeholder='E.g. email@abc.com' value={temp} onChange={(e) => setTemp(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key == "Enter" && temp != "") {
                      let users = form.users
                      let filter = users.filter((user) => user.username == temp || user.email == temp)
                      if (filter.length > 0) {
                        notify({ message: "User already invited", type: "error" })
                        setTemp("")
                        return
                      }
                      users.push({ username: temp, email: temp })
                      setForm({ ...form, users })
                      setTemp("")
                    }
                  }}
                />
              </div>
              <button className="button" style={{ width: 100 }}
                onClick={() => {
                  if (temp == "") return
                  let users = form.users
                  let filter = users.filter((user) => user.username == temp || user.email == temp)
                  if (filter.length > 0) {
                    notify({ message: "User already invited", type: "error" })
                    setTemp("")
                    return
                  }
                  users.push({ username: temp, email: temp })
                  setForm({ ...form, users })
                  setTemp("")
                }}
              >Invite</button>
            </div>
            <div style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
              marginTop: 10,
            }}>
              {form?.users?.map((user, index) => {
                return (
                  <div className="" style={{ width: "fit-content", margin: 5, backgroundColor: "gray", padding: "5px 10px", cursor: "pointer" }} onClick={() => {
                    let users = form.users
                    users.splice(index, 1)
                    setForm({ ...form, users })
                  }}>
                    <p style={{ fontSize: 12, color: "white" }}>{user.email}</p>
                  </div>
                )
              })}
            </div>
          </>}

        {form?.url && id != "create" &&
          <div>
            <p className="subtitle mt-30" style={{ fontSize: 13 }}>Share this link with your friends</p>
            <div className="row alignCenter gap-10" style={{ width: "100%" }}>
              <div className='mt-10'>
                <QRCode value={config.local_url + "/" + form.url} size={100} id='qr-code' />
              </div>
              <div>
                <p className='text-input cursor-pointer' style={{ fontSize: 12, color: "gray" }}
                  onClick={() => {
                    copy.default(config.local_url + "/" + form.url)
                    notify({ message: "Copied to clipboard", type: "success" })
                  }}
                >{config.endpoint + "/" + form.url}</p>
                <button className="backgroundLess"
                  onClick={() => {
                    downloadQr()
                  }}
                >Download QR Code</button>
              </div>
            </div>
          </div>
        }
      </div>


      <div className="card mt-10">
        <p className="card-title">Permissions</p>
        <p className="subtitle" style={{ fontSize: 13 }}>Define what your group can do</p>

        <div className="column gap-10 mt-20" style={{ width: "100%" }}>
          <div className="row justifySplit alignCenter" style={{ width: "100%" }}>
            <label className='input-label' htmlFor="can_view_all" style={{ fontSize: 16 }}>See Event's Collection</label>
            <Switch
              checked={form.can_view_all == 1 ? true : false}
              onChange={(e) => setForm({ ...form, can_view_all: e })}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            />
          </div>
          <div className="row justifySplit alignCenter" style={{ width: "100%" }}>
            <label className='input-label' htmlFor="can_download_all" style={{ fontSize: 16 }}>Allow Download</label>
            <Switch
              checked={form.can_download_all == 1 ? true : false}
              onChange={(e) => setForm({ ...form, can_download_all: e })}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            />
          </div>
          <div className="row justifySplit alignCenter" style={{ width: "100%" }}>
            <label className='input-label' htmlFor="can_view_users" style={{ fontSize: 16 }}>View other guest</label>
            <Switch
              checked={form.can_view_users == 1 ? true : false}
              onChange={(e) => setForm({ ...form, can_view_users: e })}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            />
          </div>
          <div className="row justifySplit alignCenter" style={{ width: "100%" }}>
            <div className='column' style={{}}>
              <label className='input-label' htmlFor="guest_mode" style={{ fontSize: 16 }}>Guest Mode</label>
              <label className='subtitle' htmlFor="guest_mode" style={{ fontSize: 13 }}>{form.guest_mode ? "Anyone can share without logging in" : "Guest are required to log into WePics ©"}</label>
            </div>
            <Switch
              checked={form.guest_mode == 1 ? true : false}
              onChange={(e) => setForm({ ...form, guest_mode: e })}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            />
          </div>
          <div className="row justifySplit alignCenter" style={{ width: "100%" }}>
            <label className='input-label' htmlFor="is_unlimited" style={{ fontSize: 16 }}>Unlimited Upload</label>
            <Switch
              checked={form.is_unlimited == 1 ? true : false}
              onChange={(e) => setForm({ ...form, is_unlimited: e })}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            />
          </div>
          {!form.is_unlimited && <div className="row justifySplit alignCenter" style={{ width: "100%" }}>
            <label className='input-label' htmlFor="limit" style={{ fontSize: 16 }}>Upload Limit</label>
            <input type="number" name="limit" id="limit" className='text-input' placeholder='E.g. 100' value={form.limit} onChange={(e) => setForm({ ...form, limit: e.target.value })} />
          </div>}
        </div>
      </div>
      <div className="row justifyEnd alignEnd mt-20" style={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <button className="button" style={{}}
          onClick={() => {
            if (id == "create") {
              createEvent()
              return
            }
            editEvent()
          }}
        >{id == "create" ? "Create" : "Save"}</button>
      </div>
    </MainLayout>
  )
}
