import React, { useState, useEffect, useLayoutEffect } from 'react'
import Navbar from '../../components/Navbar'
import UserHeader from '../../components/UserHeader'
import { useNavigate, useParams } from 'react-router'
import { useFetchAuthorisation, authorisationConverter } from '../../utils/AppFunctions'
import config from '../../app-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { MdClose, MdDownload, MdEvent } from 'react-icons/md'
import notify from '../../utils/IANotification'
import MainLayout from '../../layouts/MainLayout'

export default function EventGalleryPage() {
  const navigate = useNavigate()
  const { isAuthorised, userRole } = useFetchAuthorisation("user")
  let user_data = JSON.parse(sessionStorage.getItem("user"))

  let { event_code } = useParams()
  const [povStream, setPovStream] = useState([])
  const [loading, setLoading] = useState(true)
  const [eventData, setEventData] = useState({})

  const [showGalleryView, setShowGalleryView] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)
  const minSwipeDistance = 100


  const [photoDimensions, setPhotoDimensions] = useState(document.getElementById("container")?.offsetWidth / 3 || 100)

  useEffect(() => {
    window.addEventListener("resize", () => {
      setPhotoDimensions(document.getElementById("container")?.offsetWidth / 3)
    })
  }, [])

  useLayoutEffect(() => {
    setPhotoDimensions(document.getElementById("container")?.offsetWidth / 3)
  }, [])




  const fetchPovStream = async () => {
    if (!event_code) return
    let endpoint = config.endpoint + `/pov/stream/${event_code}?` + (povStream.length > 0 ? `cuurent=${povStream[0].id}` : "")
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include"
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      // success
      setPovStream(data.data?.reverse().map((pov) => {
        let src = config.endpoint + pov?.file_data?.ext_file_path

        return {
          ...pov,
          src: src,
          height: Math.floor(Math.random() * 5),
          width: Math.floor(Math.random() * 5),
        }
      }))
    }
  }

  const fetchData = async () => {
    let endpoint = config.endpoint + "/events/url/" + event_code
    let fetchResponse = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
        setEventData(data.data)
        document.title = data.data.name
    }
  }


  const downloadAll = async () => {
    let endpoint = config.endpoint + `/pov/download/${event_code}`
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include"
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      window.open(`${config.endpoint}${data.data?.url}`, "_blank")
    } else {
      notify({ type: "error", message: data.message })
    }
  }

  useEffect(() => {
    fetchPovStream()
    fetchData()

  }, [])

  useEffect(() => {
    let interval = setInterval(() => {
      fetchPovStream()
    }, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [povStream])

  const GalleryView = () => {
    return (
      <div style={{ position: "fixed", top: 0, left: 0, width: "100dvw", height: "100dvh", backgroundColor: "rgba(0,0,0,0.9)", zIndex: 1000 }}>
        <div>
          <MdClose size={30} color='white' style={{ position: "fixed", top: 10, right: 10, cursor: "pointer" }}
            onClick={() => {
              setShowGalleryView(false)
            }}
          />
        </div>
        <div>
          <button className="backgroundLess"
            onClick={() => {
              let a = document.createElement('a')
              a.href = povStream[selectedImageIndex].src
              a.download = "pov.jpg"
              a.click()
            }}
          >
            <MdDownload size={30} color='white' style={{ position: "fixed", top: 10, left: 10, cursor: "pointer" }} />
          </button>
        </div>
        <div className="" style={{ height: 400, width: "100dvw", marginTop: 70 }}
          // determine if user swiped left or right
          onTouchStart={e => setTouchStart(e.touches[0].clientX)}
          onTouchMove={e => setTouchEnd(e.touches[0].clientX)}
          onTouchEnd={() => {
            if ((touchStart - touchEnd) > minSwipeDistance) {
              // do your stuff here for left swipe
              if (selectedImageIndex < povStream.length - 1) {
                setSelectedImageIndex(selectedImageIndex + 1)
                alert("swiped left")
              }
            }
            if ((touchStart - touchEnd) < -minSwipeDistance) {
              // do your stuff here for right swipe
              if (selectedImageIndex > 0) {
                setSelectedImageIndex(selectedImageIndex - 1)
                alert("swiped right")
              }
            }
          }}
        >
          <img src={povStream[selectedImageIndex].src} alt="" style={{ width: "100%", maxHeight: "100%", objectFit: "contain", pointerEvents: "none", }} />
        </div>
        <div className="hide-scrollbars" style={{ display: "flex", flexDirection: "row", overflow: "scroll", marginTop: 30, padding: 10, alignItems: "center", width: "100%" }}
          id="gallery-view"
        >
          {povStream.length > 0 &&
            povStream.map((pov, index) => {
              return (
                <div style={{ padding: 5, borderRadius: 5, backgroundColor: selectedImageIndex === index ? "white" : "transparent" }}
                  onClick={() => {
                    setSelectedImageIndex(index)
                    // scroll to the selected image
                    let galleryView = document.getElementById("gallery-view")
                    let selectedImage = document.getElementById(`selected-image-${index}`)
                    if (galleryView && selectedImage) {
                      galleryView.scrollTo({
                        left: selectedImage.offsetLeft - 100,
                        behavior: "smooth"
                      })
                    }
                  }}
                >
                  <img src={pov.src} alt="" style={{ minWidth: 100, minHeight: 100, width: 100, height: 100, maxWidth: 100, maxHeight: 100, objectFit: "cover", pointerEvents: "none" }}
                    id={`selected-image-${index}`}
                    loading='lazy'
                  />
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  return (
    <MainLayout>
      {showGalleryView && <GalleryView />}
      <div className="row gap-10 alignCenter justifySplit mb-20">
        <p style={{ fontWeight: "600", fontSize: 25 }}>Event Gallery</p>
        <button className="backgroundLess row gap-10 alignCenter"
          onClick={() => {
            downloadAll()
          }}
        >
          <MdDownload size={20} color='gray' />
          Download All
        </button>
      </div>
      <div className="row gap-10 mb-30">
        <button className="row backgroundLess gap-10 alignCenter"
          onClick={() => {
            navigate(`/events/${eventData.id}`)
          }}
        >
          <MdEvent size={20} color='gray' />
          <p style={{ color: "gray" }}>View Event</p>
        </button>
      </div>
      <div className="row" style={{ width: "100%", flexWrap: "wrap", gap: 10 }}>
        {povStream.length > 0 &&
          povStream.map((pov, index) => {
            return (
              <div style={{}}
                onClick={() => {
                  setShowGalleryView(true)
                  setSelectedImageIndex(povStream.indexOf(pov))

                  // scroll to the selected image
                  let galleryView = document.getElementById("gallery-view")
                  let selectedImage = document.getElementById(`selected-image-${povStream.indexOf(pov)}`)
                  if (galleryView && selectedImage) {
                    galleryView.scrollTo({
                      left: selectedImage.offsetLeft - 100,
                      behavior: "smooth"
                    })
                  }
                }}
              >
                <img src={pov.src} alt="" style={{ width: `${photoDimensions - 10}px`, height: `${photoDimensions - 10}px`, objectFit: "cover", cursor: "pointer" }} loading='lazy' id={`image-${index}`} />
              </div>
            )
          })
        }
      </div>
    </MainLayout>
  )
}
