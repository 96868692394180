import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import config from '../../app-config'
import { tidyDate, tidyDateRaw } from '../../utils/helper'
import { MdAdsClick } from 'react-icons/md'

export default function LandingPage() {
    let url = useParams().url
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate()

    const loadEvent = async () => {
        let endpoint = config.endpoint + "/events/url/" + url
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            setData(data.data)
            document.title = data.data.name

        } else {
            // navigate("/error")
        }
        setIsLoading(false)
    }

    useEffect(() => {
        loadEvent()
    }, [])

    const enterEvent = async () => {
        if (!data.id) return
        let endpoint = config.endpoint + "/events/enter/id/" + data.id
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let response = await fetchResponse.json()
        if (response.status === 200) {
            navigate(`/${data?.url}/home`)
        } else {
            navigate("/user/auth/login")
        }
    }

    return (
        <>
            {!isLoading ? <>
                <div style={{ backgroundColor: "black" }}
                    onClick={() => {
                        enterEvent()
                    }}
                >
                    <img src={`${config.endpoint}${data?.header_image?.file_path}`} alt="" style={{ width: "100dwh", height: "100dvh", objectFit: "cover", opacity: 0.5 }} />
                    <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "white", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%" }}>
                        {/* <img src="/icon.png" alt="" style={{ height: 100, width: 100, borderRadius: 20 }} /> */}
                        <h1 style={{ fontWeight: 900, fontSize: 35, marginTop: 10 }}>{data?.name}</h1>
                        <p style={{ fontSize: 13, letterSpacing: 1 }}>{data?.description}</p>
                        <p style={{ fontWeight: "normal", marginTop: 10, marginBottom: 100 }}>{tidyDateRaw(data?.start_date)?.day} {tidyDateRaw(data?.start_date)?.month} {tidyDateRaw(data?.start_date)?.year}</p>

                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                            {/* <button style={{ padding: 10, backgroundColor: "white", minWidth: 200, borderRadius: 10, border: "none", marginRight: 10, color: "#5FB3F9", fontWeight: "900", letterSpacing: 0 }}
                                onClick={() => {
                                    enterEvent()
                                }}
                            >Enter</button> */}
                        </div>
                    </div>

                    <div className="row"
                        style={{ position: "absolute", bottom: 20, zIndex: 1000, width: "100%", justifyContent: "center" }}
                    >
                        <div className="column center alignCenter w-100">
                            {/* <div className="ripple" style={{ backgroundColor: "white" }} /> */}
                            <MdAdsClick size={30} color="white" className='ripple' style={{ backgroundColor: "gray" }} />
                        </div>
                        <p style={{ color: "white", textAlign: "center", width: "100%" }}>Tap anywhere to start!</p>
                        <p style={{ fontSize: 12, textAlign: "center", width: "100%", color: "gray" }}
                            onClick={() => {
                                window.open("https://www.ourcodelab.com")
                            }}
                        >Powered by OurCodeLab</p>
                    </div>
                </div>
            </>
                :
                <>

                </>
            }
        </>
    )
}
