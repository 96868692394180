import React from 'react'
import '../globals.css'

export default function Header() {
    return (
        <div style={{ borderBottom: "1px solid gray", width: "100%", backgroundColor: "white", display: "flex", }} className='justifyCenter alignCenter'>
            <div className="header row alignCenter justifySplit" style={{ padding: 10, maxWidth: 1500 }}>
                <div className="row gap-20">
                    <img src="/icon.png" alt="logo" style={{ width: "150px", height: "50px", objectFit: "contain" }} />
                    <div className="row gap-10 alignCenter">
                        {/* <a className='a-link' href="/#resources">Resouces</a>
                        <a className='a-link' href="/#product">Product</a>
                        <a className='a-link' href="/#features">Features</a> */}
                    </div>
                </div>
                <div className="row gap-20 alignCenter">
                    {/* <a className='a-link' href="/#pricing">Pricing</a>
                    <a className='a-link' href="/#sales">Contact Sales</a> */}
                    <a className='a-link' href="/auth/login">Log In</a>
                    <button
                        style={{
                            backgroundColor: "#2F80ED",
                            color: "white",
                            borderRadius: "50px",
                            border:"none",
                            outline:"none",
                            fontSize: "14px",
                            padding: "10px 20px",
                        }}
                        onClick={() => { window.location.href = "/auth/register" }}
                    >Get Started</button>
                </div>
            </div>
        </div>
    )
}
