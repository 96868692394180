import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import config from '../app-config'

export default function UserNav() {
    const navigate = useNavigate()
    const { url } = useParams()
    const [data, setData] = useState({})

    const currentLocation = window.location.pathname

    const loadEvent = async () => {
        let endpoint = config.endpoint + "/events/url/" + url
        let fetchResponse = await fetch(endpoint, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        })
        let data = await fetchResponse.json()
        if (data.status === 200) {
            setData(data.data)
            document.title = data.data.name
            window.sessionStorage.setItem("event", JSON.stringify(data.data))
        }
    }

    useEffect(() => {
        if (window.sessionStorage.getItem("event")) {
            let data = JSON.parse(window.sessionStorage.getItem("event"))
            setData(data)
            document.title = data.name
        }
        loadEvent()
    }, [])

    let bgColour = data.colours || "blue"
    let fgColour

    // Calculate the contrast of the background colour, and set the text colour accordingly
    let r = parseInt(bgColour.substr(1, 2), 16)
    let g = parseInt(bgColour.substr(3, 2), 16)
    let b = parseInt(bgColour.substr(5, 2), 16)
    let uicolors = [r / 255, g / 255, b / 255]
    let c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92
        }
        return Math.pow((col + 0.055) / 1.055, 2.4)
    })
    let L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
    fgColour = L > 0.179 ? "black" : "white"

    let opFgColour = fgColour === "black" ? "white" : "black"


    

    return (
        <div style={{ backgroundColor: "white", position: "fixed", top: 10, width: "90%", left: "50%", transform: "translateX(-50%)", borderRadius: "30px", padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", zIndex: 1000 }}>
            <p style={{
                fontSize: 12,
                width: "100%",
                textAlign: "center",
                backgroundColor: currentLocation.includes("/home") ? (data.colours || "blue" ): "transparent",
                color: currentLocation.includes("home") ? fgColour: "black",
                padding: 10,
                borderTopLeftRadius: 30,
                borderBottomLeftRadius: 30,
                textOverflow: "ellipsis",
            }} onClick={() => {
                navigate(`/${url}/home`)
            }}>{data?.name ? data?.name : ""}</p>
            <p style={{
                fontSize: 12,
                width: "100%",
                textAlign: "center",
                backgroundColor: currentLocation.includes("/camera") ? (data.colours || "blue" ): "transparent",
                color: currentLocation.includes("camera") ? fgColour: "black",
                borderTopRightRadius: 30,
                borderBottomRightRadius: 30,
                padding: 10
            }} onClick={() => {
                navigate(`/${url}/camera`)
            }}>Camera</p>
        </div>
    )
}
