import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import './App.css';
import './globals.css'
import './animation.css'
import IndexPage from "./pages/IndexPage";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/user/HomePage";
import EventsPage from "./pages/user/EventsPage";
import EventsFormPage from "./pages/user/EventsFormPage";
import LandingPage from "./pages/guest/LandingPage";
import ErrorPage from "./pages/guest/ErrorPage";
import UserHomePage from "./pages/guest/UserHomePage";
import CameraPage from "./pages/guest/CameraPage";
import EventGalleryPage from "./pages/user/EventGalleryPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/auth/login" element={<AuthPage pageState={"login"}/>} />
        <Route path="/auth/register" element={<AuthPage pageState={"register"}/>} />

        {/* <Route path="/home" element={<HomePage/>} /> */}
        <Route path="/home" element={<EventsPage/>} />
        <Route path="/events" element={<EventsPage/>} />
        <Route path="/events/:event_id" element={<EventsFormPage/>} />
        <Route path="/events/gallery/:event_code" element={<EventGalleryPage/>} />
        <Route path="/:url" element={<LandingPage/>} />
        <Route path="/:url/home" element={<UserHomePage/>} />
        <Route path="/:url/camera" element={<CameraPage/>} />
        {/* <Route path="/:url/home" element={<UserHomePage/>} /> */}

        <Route path="/error" element={<ErrorPage/>} />
        <Route path="*" element={<ErrorPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
