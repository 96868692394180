import React, { useState, useEffect, useRef, useCallback } from 'react'
import { MdClose, MdSwitchCamera } from 'react-icons/md';
import Webcam from "react-webcam";
import UserNav from '../../components/UserNav';
import { useParams } from 'react-router-dom';
import config from '../../app-config';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'


export default function CameraPage() {

    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)

    const [timeLoaded, setTimeLoaded] = useState(new Date().getTime())
    const { url } = useParams()

    const camera = useRef(null);

    useEffect(() => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }, [])

    window.addEventListener('resize', () => {
        if (new Date().getTime() - timeLoaded < 3000) return
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    })

    const [cameraData, setCameraData] = useState([])
    const [cameraMode, setCameraMode] = useState("user") // user, environment
    const [pageState, setPageState] = useState("capture") // capture, preview
    const [ready, setReady] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0) // Add progress state

    // const submitDocuments = async () => {
    //   if (isUploading) return
    //   setIsUploading(true)
    //   setUploadProgress(0) // Reset progress bar

    //   let formData = new FormData()
    //   for (let i = 0; i < filesToUpload.length; i++) {
    //     formData.append("files", filesToUpload[i])
    //   }
    //   formData.append("event_code", event_code)

    //   const xhr = new XMLHttpRequest()
    //   xhr.open("POST", `${config.endpoint}/pov/upload`, true)
    //   xhr.withCredentials = true

    //   xhr.upload.onprogress = (e) => {
    //     if (e.lengthComputable) {
    //       const percentComplete = Math.round((e.loaded / e.total) * 100)
    //       setUploadProgress(percentComplete)
    //     }
    //   }

    //   xhr.onload = async () => {
    //     setIsUploading(false)
    //     setUploadProgress(0) // Hide progress bar on complete
    //     if (xhr.status === 200) {
    //       await fetchPovStream()
    //       setFilesToUpload([])
    //     } else {
    //       notify({ message: JSON.parse(xhr.responseText).message, type: "error" })
    //     }
    //   }

    //   xhr.send(formData)
    // }


    const uploadImage = async () => {
        if (isUploading) return
        setIsUploading(true)
        setUploadProgress(0) // Reset progress bar
        const formData = new FormData()
        cameraData.forEach((media, index) => {
            // media.data is base64, convert to blob
            const parts = media?.data?.split(';base64,');
            const contentType = parts[0].split(":")[1];
            const raw = window.atob(parts[1]);
            const rawLength = raw.length;
            const uInt8Array = new Uint8Array(rawLength);
            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            const blob = new Blob([uInt8Array], { type: contentType });


            if (media.type === "image") {
                formData.append("files", blob)
            } else {
                formData.append("files", blob)
            }
        })
        formData.append("event_code", url)

        formData.append("upload_preset", "pov")
        let endpoint = config.endpoint + "/pov/upload"
        const xhr = new XMLHttpRequest()
        xhr.open("POST", endpoint, true)
        xhr.withCredentials = true
        xhr.upload.onprogress = (e) => {
            if (e.lengthComputable) {
                const percentComplete = Math.round((e.loaded / e.total) * 100)
                setUploadProgress(percentComplete)
            }
        }
        xhr.onload = async () => {
            setIsUploading(false)
            setUploadProgress(0) // Hide progress bar on complete
            if (xhr.status === 200) {
                setCameraData([])
            } else {
                console.log(JSON.parse(xhr.responseText).message)
            }
        }
        xhr.send(formData)
    }


    // const [holdDuration, setHoldDuration] = useState(0)

    // const mediaRecorderRef = useRef(null);
    // const [capturing, setCapturing] = useState(false);
    // const [recordedChunks, setRecordedChunks] = useState([]);

    // const handleDataAvailable = useCallback(
    //     ({ data }) => {
    //         if (data.size > 0) {
    //             setRecordedChunks((prev) => prev.concat(data));
    //             setCameraData(
    //                 [...cameraData, {
    //                     type: "video/webm",
    //                     data: URL.createObjectURL(new Blob([data], { type: "video/webm" }))
    //                 }]
    //             )
    //         }
    //     },
    //     [setRecordedChunks]
    // );

    // const handleStartCaptureClick = useCallback(() => {
    //     setCapturing(true);
    //     mediaRecorderRef.current = new MediaRecorder(camera.current.stream, {
    //         mimeType: "video/webm",
    //     });
    //     mediaRecorderRef.current.addEventListener(
    //         "dataavailable",
    //         handleDataAvailable
    //     );
    //     mediaRecorderRef.current.start();
    // }, [camera, setCapturing, mediaRecorderRef, handleDataAvailable]);

    // const handleStopCaptureClick = useCallback(() => {
    //     mediaRecorderRef.current.stop();
    //     setCapturing(false);
    //   }, [mediaRecorderRef, setCapturing]);

    // const handleDownload = useCallback(() => {
    //     if (recordedChunks.length) {
    //       const blob = new Blob(recordedChunks, {
    //         type: "video/webm",
    //       });
    //       const url = URL.createObjectURL(blob);
    //       const a = document.createElement("a");
    //       document.body.appendChild(a);
    //       a.style = "display: none";
    //       a.href = url;
    //       a.download = "react-webcam-stream-capture.webm";
    //       a.click();
    //       window.URL.revokeObjectURL(url);
    //       setRecordedChunks([]);
    //     }
    //   }, [recordedChunks]);


    //   useEffect(() => {
    //     console.log(capturing ? "Capturing" : "Not capturing");
    //   }, [capturing]);

    return (
        <>
            {pageState === "capture" && <>
                <div>
                    {/* Camera Finder */}
                    <div
                        onDoubleClick={() => {
                            if (cameraMode === "user") {
                                setCameraMode("environment")
                            } else {
                                setCameraMode("user")
                            }
                        }}
                    >
                        <Webcam
                            audio={false}
                            // height={height}
                            ref={camera}
                            screenshotFormat="image/jpeg"
                            // width={width}
                            onLoad={() => { setReady(true) }}
                            videoConstraints={{
                                facingMode: cameraMode,
                                width: height,
                                height: width
                            }}
                            imageSmoothing={true}
                            mirrored={cameraMode === "user" ? true : false}
                        />
                        {/* <input type="file" accept="image/*" capture="environment" onChange={(e) => {
                            const imageSrc = URL.createObjectURL(e.target.files[0]);
                            setCameraData(
                                [...cameraData, {
                                    type: "image",
                                    data: imageSrc
                                }]
                            )
                        }} /> */}
                    </div>
                    <button
                        style={{ appearance: "none", backgroundColor: "transparent", border: "none", position: "absolute", padding: "10px", borderRadius: "50%", bottom: 30, left: 30, }}
                        onClick={() => {
                            if (cameraMode === "user") {
                                setCameraMode("environment")
                            } else {
                                setCameraMode("user")
                            }
                        }}>
                        <MdSwitchCamera size={50} color='white' />
                    </button>

                    {/* Bottom Camera Controls */}
                    <button
                        style={{ appearance: "none", backgroundColor: "white", border: "none", height: "100px", width: "100px", position: "absolute", padding: "10px", borderRadius: "50%", bottom: 10, left: "50%", transform: "translateX(-50%)" }}
                        onClick={() => {
                            const imageSrc = camera.current.getScreenshot();
                            setCameraData(
                                [...cameraData, {
                                    type: "image",
                                    data: imageSrc
                                }]
                            )
                        }}
                    ></button>
                    {/* <Tooltip id="my-tooltip" /> */}
                    <div
                        data-tip data-for='previewTool'
                        onClick={() => setPageState("preview")}
                    >
                        {cameraData.length > 0 ? <img src={cameraData[cameraData.length - 1].data} style={{ width: "100px", height: "100px", objectFit: "cover", position: "absolute", bottom: 10, right: 10 }} alt="" /> :
                            <div style={{ width: "100px", height: "100px", backgroundColor: "black", position: "absolute", bottom: 10, right: 10 }}></div>
                        }
                    </div>

                    {/*  */}
                    <UserNav />
                </div>
            </>}

            {pageState === "preview" && <>
                {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}> */}
                {/* <div style={{ display: "flex", flexDirection: "row", gap: 10, flexWrap: "wrap" }}> */}
                <div className="row p-all">
                    {cameraData.map((media, index) => {
                        if (media.type === "image") {
                            return (
                                <div className="column" style={{ width: "33.33%", padding: 2 }}>
                                    <div style={{
                                        position: "relative", top: 10, left: 110, zIndex: 5
                                        , backgroundColor: "white", borderRadius: "50%", padding: 5, width: 20, height: 20, flexDirection: "row", alignItems: "center", justifyContent: "center", display: "flex", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)"
                                    }}
                                        onClick={() => {
                                            let newCameraData = cameraData
                                            newCameraData.splice(index, 1)
                                            setCameraData([...newCameraData])
                                        }}
                                    >
                                        <MdClose size={15} color='gray' />
                                    </div>
                                    <div style={{ position: "relative", width: "100%", height: 0, paddingBottom: "100%", backgroundColor: "gray", borderRadius: 5, overflow: "hidden" }}>
                                        <img src={media.data} style={{ width: "100%", height: "100%", objectFit: "cover", position: "absolute" }} alt="" />
                                    </div>
                                </div>
                            )
                        } else {
                            return <video src={media.data} style={{ width: "100px", height: "100px", objectFit: "cover", }} alt="" />
                        }
                    })}
                </div>
                {/* <button
                        style={{ appearance: "none", backgroundColor: "white", border: "none", height: "100px", width: "100px", position: "absolute", padding: "10px", borderRadius: "50%", bottom: 10, left: "50%", transform: "translateX(-50%)" }}
                        onClick={() => {
                            setPageState("capture")
                        }}></button> */}

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", position: "fixed", bottom: 0, backgroundColor: "white", padding: 20 }}>
                    {cameraData.length > 0 ? <>
                        <button
                            className='button'
                            style={{ width: "100%", backgroundColor: isUploading ? "gray" : "blue" }}
                            onClick={() => {
                                uploadImage()
                            }}
                            disabled={isUploading}
                        >
                            {isUploading ? `Uploading...(${uploadProgress}%)` : "Upload"}
                        </button>
                    </>
                        :
                        <p>Psss... there's nothing here for now, capture your POV 📸 and you will find them here!</p>
                    }
                    <button className="backgroundLess mt-10"
                        onClick={() => {
                            setPageState("capture")
                        }}
                    >
                        Back
                    </button>
                </div>
                {/* </div> */}
            </>}
        </>
    )
}
