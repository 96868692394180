import React, { useEffect } from 'react'
import Header from '../components/Header'

export default function IndexPage() {

  // create a background of moving particles

  /*
  useEffect(() => {
    let particles = []
    let particleCount = 100
    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        size: Math.random() * 5,
        speedX: (Math.random() - 0.5) * 2,
        speedY: (Math.random() - 0.5) * 2,
      })
    }

    // create a canvas to draw the particles
    let canvas = document.createElement('canvas')
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    canvas.style.position = 'absolute'
    canvas.style.top = 0
    canvas.style.left = 0
    canvas.style.zIndex = -1
    document.getElementById('container').appendChild(canvas)
    let ctx = canvas.getContext('2d')

    // draw the particles
    function drawParticles() {
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight)
      for (let i = 0; i < particleCount; i++) {
        let p = particles[i]
        ctx.beginPath()
        ctx.arc(p.x, p.y, p.size, 0, Math.PI * 2)
        // Random color
        ctx.fillStyle = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 1)`
        ctx.fill()
        p.x += p.speedX
        p.y += p.speedY
        if (p.x < 0 || p.x > window.innerWidth) p.speedX *= -1
        if (p.y < 0 || p.y > window.innerHeight) p.speedY *= -1
      }
    }

    // update the particles
    function updateParticles() {
      drawParticles()
      requestAnimationFrame(updateParticles)
    }

    updateParticles()
  }, [document.getElementById('container')])
*/

  return (
    <div>
      <Header />
      <div className="column alignCenter justifyCenter" style={{ height: `calc(100vh - 200px)`, width: "100%", }} id='container'>
        <h1 style={{ fontSize: 50, fontWeight: "normal" }}>WePics</h1>
        <h2 style={{ fontSize: 20, fontWeight: "normal" }}>A simple way to capture precious moments</h2>
        <p style={{ fontSize: 12, }}>A passion project by <a href="https://www.ourcodelab.com">OurCodeLab</a></p>
      </div>
    </div>
  )
}
