const tidyDate = (date) => {
    // if (!date?.includes("Z")) {
    //     date = date + "Z"
    // }
    let day = new Date(date).getDate()
    let month = new Date(date).getMonth() + 1
    let year = new Date(date).getFullYear()
    let hour = new Date(date).getHours()
    let minute = new Date(date).getMinutes()

    if (day < 10) {
        day = "0" + day
    }

    if (month < 10) {
        month = "0" + month
    }

    if (hour < 10) {
        hour = "0" + hour
    }

    if (minute < 10) {
        minute = "0" + minute
    }

    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Nov", "Dec"]

    return `${day} ${MONTHS[month - 1]} ${year} ${hour}:${minute}`
}

const tidyDateRaw = (date) => {
    // if (!date?.includes("Z")) {
    //     date = date + "Z"
    // }
    let day = new Date(date).getDate()
    let month = new Date(date).getMonth() + 1
    let year = new Date(date).getFullYear()
    let hour = new Date(date).getHours()
    let minute = new Date(date).getMinutes()

    if (day < 10) {
        day = "0" + day
    }

    if (month < 10) {
        month = "0" + month
    }

    if (hour < 10) {
        hour = "0" + hour
    }

    if (minute < 10) {
        minute = "0" + minute
    }

    const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Nov", "Dec"]

    return {
        day: day,
        month: MONTHS[month - 1],
        year: year,
        hour: hour,
        minute: minute
    
    }
}



const currency = (amount) => {
    amount = Math.round((amount) * 100) / 100
    
    if (amount.toString().includes(".")) {
        amount = amount.toFixed(2)
    }

    // add commas
    amount = amount.toLocaleString()

    return amount
}

const isMobile = (width) => {
    if (width < 700) {
        return true
    }else{
        return false
    }
}

module.exports = {
    tidyDate,
    currency,
    isMobile,
    tidyDateRaw,
}