import React, { useState, useEffect } from 'react'
import UserNav from '../../components/UserNav'
import { MdClose, MdDownload, MdUpload, MdUploadFile } from 'react-icons/md'
import config from '../../app-config'
import { useParams } from 'react-router-dom'
import "../../animation.css"
import "../../globals.css"
import notify from '../../utils/IANotification'


export default function UserHomePage() {
  const [filesToUpload, setFilesToUpload] = useState([])
  const event_code = useParams().url
  const [povStream, setPovStream] = useState([])
  const [showGalleryView, setShowGalleryView] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const minSwipeDistance = 10
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    let fileInput = document?.getElementById("file-input")
    let fileInputContainer = document?.getElementById("file-input-container")
    if (!fileInput || !fileInputContainer) return

    fileInput.addEventListener("dragover", (e) => {
      // add a class hover
      e.preventDefault()
      e.stopPropagation()
      fileInputContainer.classList.add("hover")
    })
    fileInput.addEventListener("dragleave", (e) => {
      e.preventDefault()
      e.stopPropagation()
      fileInputContainer.classList.remove("hover")
    })

    fileInput.addEventListener("drop", (e) => {
      e.preventDefault()
      e.stopPropagation()
      fileInputContainer.classList.remove("hover")
      // get the files
      let files = e.dataTransfer.files
      // convert to array
      files = [...files]
      let temp = [...filesToUpload]
      temp = temp.concat(files)

      // check if the file is valid
      if (temp.length > 0) {
        // set the file input to the files
        fileInput.files = temp
        // call the function to upload the files
        setFilesToUpload(temp)
      }
    })
  }, [])


  // const submitDocuments = async () => {
  //   if (isUploading) return
  //   setIsUploading(true)
  //   let formData = new FormData()
  //   for (let i = 0; i < filesToUpload.length; i++) {
  //     formData.append("files", filesToUpload[i])
  //   }
  //   formData.append("event_code", event_code)

  //   let endpoint = config.endpoint + "/pov/upload"
  //   let fetchResponse = await fetch(endpoint, {
  //     method: "POST",
  //     body: formData,
  //     credentials: "include"
  //   })
  //   let data = await fetchResponse.json()
  //   if (data.status === 200) {
  //     // success
  //     await fetchPovStream()
  //     setFilesToUpload([])
  //   } else {
  //     // error
  //     notify({ message: data.message, type: "error" })
  //   }
  //   setIsUploading(false)
  // }
  const [uploadProgress, setUploadProgress] = useState(0) // Add progress state

  const submitDocuments = async () => {
    if (isUploading) return
    setIsUploading(true)
    setUploadProgress(0) // Reset progress bar

    let formData = new FormData()
    for (let i = 0; i < filesToUpload.length; i++) {
      formData.append("files", filesToUpload[i])
    }
    formData.append("event_code", event_code)

    const xhr = new XMLHttpRequest()
    xhr.open("POST", `${config.endpoint}/pov/upload`, true)
    xhr.withCredentials = true

    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const percentComplete = Math.round((e.loaded / e.total) * 100)
        setUploadProgress(percentComplete)
      }
    }

    xhr.onload = async () => {
      setIsUploading(false)
      setUploadProgress(0) // Hide progress bar on complete
      if (xhr.status === 200) {
        await fetchPovStream()
        setFilesToUpload([])
      } else {
        notify({ message: JSON.parse(xhr.responseText).message, type: "error" })
      }
    }

    xhr.send(formData)
  }

  const fetchPovStream = async () => {
    if (!event_code) return
    let endpoint = config.endpoint + `/pov/stream/${event_code}?` + (povStream.length > 0 ? `curent=${povStream[0].id}` : "")
    let fetchResponse = await fetch(endpoint, {
      method: "GET",
      credentials: "include"
    })
    let data = await fetchResponse.json()
    if (data.status === 200) {
      // success
      setPovStream(data.data?.reverse().map((pov) => {
        let src = config.endpoint + pov?.file_data?.ext_file_path

        return {
          ...pov,
          src: src,
          height: Math.floor(Math.random() * 5),
          width: Math.floor(Math.random() * 5),
        }
      }))
    }
  }

  useEffect(() => {
    fetchPovStream()
  }, [])


  useEffect(() => {
    let interval = setInterval(() => {
      fetchPovStream()
    }, 60 * 1000)
    return () => {
      clearInterval(interval)
    }
  }, [povStream])

  const GalleryView = () => {
    return (
      <div style={{ position: "fixed", top: 0, left: 0, width: "100dvw", height: "100dvh", backgroundColor: "rgba(0,0,0,0.9)", zIndex: 1000 }}>
        <div>
          <MdClose size={30} color='white' style={{ position: "fixed", top: 10, right: 10, cursor: "pointer" }}
            onClick={() => {
              setShowGalleryView(false)
            }}
          />
        </div>
        <div>
          <button className="backgroundLess"
            onClick={() => {
              let a = document.createElement('a')
              a.href = povStream[selectedImageIndex].src
              a.download = "pov.jpg"
              a.click()
            }}
          >
            <MdDownload size={30} color='white' style={{ position: "fixed", top: 10, left: 10, cursor: "pointer" }} />
          </button>
        </div>
        <div className="" style={{ height: 400, width: "100dvw", marginTop: 70 }}
          // determine if user swiped left or right
          onTouchStart={e => setTouchStart(e.touches[0].clientX)}
          onTouchMove={e => setTouchEnd(e.touches[0].clientX)}
          onTouchEnd={() => {
            if ((touchStart - touchEnd) > minSwipeDistance) {
              // do your stuff here for left swipe
              if (selectedImageIndex < povStream.length - 1) {
                setSelectedImageIndex(selectedImageIndex + 1)
              }
            }
            if ((touchStart - touchEnd) < -minSwipeDistance) {
              // do your stuff here for right swipe
              if (selectedImageIndex > 0) {
                setSelectedImageIndex(selectedImageIndex - 1)
              }
            }
          }}
        >
          <img src={povStream[selectedImageIndex].src} alt="" style={{ width: "100%", maxHeight: "100%", objectFit: "contain", pointerEvents: "none", }} />
        </div>
        <div className="" style={{ display: "flex", flexDirection: "row", overflow: "scroll", marginTop: 30, padding: 10, alignItems: "center", width: "100%" }}
          id="gallery-view"
        >
          {povStream.length > 0 &&
            povStream.map((pov, index) => {
              return (
                <div style={{ padding: 5, borderRadius: 5, backgroundColor: selectedImageIndex === index ? "white" : "transparent" }}
                  onClick={() => {
                    setSelectedImageIndex(index)
                    // scroll to the selected image
                    let galleryView = document.getElementById("gallery-view")
                    let selectedImage = document.getElementById(`selected-image-${index}`)
                    if (galleryView && selectedImage) {
                      galleryView.scrollTo({
                        left: selectedImage.offsetLeft - 100,
                        behavior: "smooth"
                      })
                    }
                  }}
                >
                  <img src={pov.src} alt="" style={{ minWidth: 100, minHeight: 100, width: 100, height: 100, maxWidth: 100, maxHeight: 100, objectFit: "cover", pointerEvents: "none" }}
                    id={`selected-image-${index}`}
                    loading='lazy'
                  />
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }


  return (
    <div>
      <UserNav />
      {showGalleryView && povStream.length > 0 &&
        <GalleryView />
      }

      {/* POV Stream */}
      <div style={{ marginTop: 100, padding: 10 }}>
        <div className="row gap-10 alignCenter" style={{ marginBottom: 20, position: "sticky", top: 70 }}>
          <div className="ripple" style={{}} />
          <p className="input-label" style={{ fontWeight: "900", }}>Happening Now...</p>
        </div>
        {/* {povStream.length > 0 &&
          <Gallery images={povStream} enableImageSelection={false}
            tileViewportStyle={{
              // maskImage:"object-fit: cover",
              objectFit: "cover",
            }}
          />
        } */}

        <div className="row">
          {povStream.length > 0 ?
            povStream.map((pov) => {
              return (
                <div className="column" style={{ width: "33.33%", padding: 2 }}>
                  <div style={{ position: "relative", width: "100%", height: 0, paddingBottom: "100%", backgroundColor: "gray", borderRadius: 5, overflow: "hidden" }}
                    onClick={() => {
                      setShowGalleryView(true)
                      setSelectedImageIndex(povStream.indexOf(pov))
                    }}
                  >
                    <img src={pov.src} alt="" style={{ position: "absolute", width: "100%", height: "100%", objectFit: "cover", }} loading='lazy' />
                  </div>
                </div>
              )
            })
            :
            <div className="column center alignCenter" style={{ width: "100%", height: 200, backgroundColor: "rgba(0,0,0,0.1)", borderRadius: 5, alignItems: "center", justifyContent: "center" }}>
              <p style={{ fontSize: 14, color: "gray" }}>📸 Pss...Upload your POV to get started</p>
            </div>
          }
        </div>
        {/* <p style={{ fontSize: 14, textAlign: "center", marginTop: 10 }}>Built with ❤️ by Aamuel</p> */}
        <p style={{ fontSize: 12, textAlign: "center" }}
          onClick={() => {
            window.open("https://www.ourcodelab.com")
          }}
        >Powered by OurCodeLab</p>

        <div style={{ height: 150 }}></div>
      </div>
      <div>
        {/* File Upload */}
        <div id='file-input' style={{ position: "fixed", bottom: 0, width: "100%", right: 0, backgroundColor: "white", padding: 10, boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)" }}>
          <input className="input" type="file" multiple accept="image/*,video/*,audio/*" style={{ display: "none" }} id='file-tag' onChange={(e) => {
            let files = e.target.files
            files = [...files]
            setFilesToUpload(files)
          }} />
          <div className="file-input-container cursor-pointer" id='file-input-container' style={{}}
            onClick={() => {
              document.getElementById("file-tag").click()
            }}
          >
            <p className="input-label" style={{ fontWeight: "900", textAlign: "left", width: "100%" }}>Add your POV 📸</p>
            <div className="row gap-10 mt-10">
              <MdUpload size={20} color='gray' />
              <p style={{ fontSize: 10, color: "gray" }}>Drag and drop files here or click to browse</p>
            </div>
          </div>
          {filesToUpload.length > 0 &&
            <div style={{ display: "flex", overflowX: "scroll", flexDirection: "row", gap: 10, }} className='hide-scrollbars'>
              {filesToUpload.map((file, index) => {
                return (
                  <div style={{
                    // backgroundColor: "gray",
                    display: "flex",
                    gap: 5,
                    flexDirection: "row",
                    alignItems: "center",
                    // padding: "5px 10px",
                    cursor: "pointer",
                    borderRadius: 5
                  }}
                    onClick={() => {
                      let newFilesToUpload = filesToUpload
                      newFilesToUpload.splice(index, 1)
                      setFilesToUpload([...newFilesToUpload])
                    }}
                  >
                    <div>
                      <div style={{ position: "relative", top: 10, left: 140, backgroundColor: "white", borderRadius: "50%", padding: 5, width: 20, height: 20, flexDirection: "row", alignItems: "center", justifyContent: "center", display: "flex", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)" }}>
                        <MdClose size={15} color='gray' />
                      </div>
                      <img src={URL.createObjectURL(file)} alt="" style={{ minWidth: 150, minHeight: 150, maxWidth: 150, maxHeight: 150, objectFit: "cover", borderRadius: 5 }} loading='lazy' />
                    </div>
                  </div>
                )
              })}
            </div>
          }
          {filesToUpload.length > 0 && <div className="column right alignEnd w-100">
            <button className='row alignCenter gap-10' style={{ border: isUploading ? "2px solid gray" : "2px solid blue", padding: "10px 15px", minWidth: 120, borderRadius: 5, width: "auto", marginTop: 10, backgroundColor: isUploading ? "gray" : "blue", color: "white", fontSize: 14 }}
              disabled={isUploading}
              onClick={() => {
                submitDocuments()
              }}>
              <MdUpload size={20} />
              {isUploading ? `Uploading... (${uploadProgress}%)` : "Upload"}
            </button>
          </div>}
        </div>
      </div>
    </div>
  )
}
