import React, { useEffect, useState, useLayoutEffect } from 'react'
import config from '../app-config'
import { useNavigate } from 'react-router-dom'

export default function UserHeader() {
    // const [imageHeight, setImageHeight] = useState(document.getElementById("logo-navbar")?.getClientRects()[0].bottom)

    // useLayoutEffect(() => {

    //     setImageHeight(document.getElementById("logo-navbar").getClientRects()[0].bottom)
    // }, [imageHeight])

    const navigate = useNavigate()

    return (
        <div style={{ width: "100%", padding: 10, backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", position: "sticky", top: 0, zIndex: 1000 }} id='user-header'>
            <div className="row justifySplit alignCenter">
                <img src="/icon.png" alt="" style={{ height: 80, width: 100, objectFit: "contain" }} />
            </div>
            <div className="row center gap-10">
                {/* <button className="nav-link" style={{ marginRight: 10 }} onClick={() => navigate("/home")}>Home</button> */}
                {/* <button className="nav-link" style={{ marginRight: 10 }} onClick={() => navigate("/events")}>Events</button> */}
                {/* <button className="nav-link" style={{ marginRight: 10 }} onClick={() => navigate("/profile")}>Profile</button> */}
            </div>
        </div>
    )
}
